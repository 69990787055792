<template>
	<Layout>
		<Header :bordered="false" />
		<section class="profile columns">
			<Sidebar />
			<div class="column">
				<slot class="profile__column panel" :user="user"></slot>
			</div>
		</section>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Sidebar from '@/pages/profile/Sidebar'
import eventHub from '@/services/eventHub'

export default {
	name: 'Profile',
	components: {
		Layout,
		Header,
		Sidebar
	},
	data() {
		return {
			user: {}
		}
	},
	mounted() {
		eventHub.$on('me', obj => {
			this.user = obj
		})
	}
}
</script>
